import Banner from "../containers/WebApp/CaseStudies/Banner";
import Section from "../containers/WebApp/CaseStudies/Section";
import SEO from "../components/SEO";
import React from "react";

const CaseStudies = () => {
  return (
    <>
      <SEO title="Case Studies" />
      <Banner />
      <Section />
    </>
  );
};

export default CaseStudies;
