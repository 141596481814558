import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Text from "../../../../common/components/Text";
import Container from "../../../../common/components/UI/Container";
import { SECTION_CASE_STUDIES } from "../../../../common/data/WebApp";
import { Link } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";

import React from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import SectionArea, { Row, ColumnContents } from "./section.style";
const Section = () => {
  const { sections } = SECTION_CASE_STUDIES;
  return (
    <ColumnContents>
      <SectionArea>
        <Container>
          <Row className="rowContents">
            <Box className="blockTitle">
              <Heading as="h2" content={sections[0].title} />
              <Text as="p" content={sections[0].text} />
              <Link className="button" to={sections[0].button.link}>
                <span>
                  {sections[0].button.label}
                  <Icon icon={androidArrowForward} size={16} />
                </span>
              </Link>
            </Box>
            <Box className="postsWrap">
              <img src={sections[0].image} alt="x-rd" />
              {/* <StaticImage
              src="../images/logo-ascs.png"
              alt="australian cyber security centre"
              placeholder="blurred"
              objectFit="contain"
              objectPosition="center"
            /> */}
            </Box>
          </Row>
        </Container>
      </SectionArea>
      <SectionArea>
        <Container>
          <Row className="rowContents">
            <Box className="postsWrap">
              <img src={sections[1].image} alt="x-rd" />
              {/* <StaticImage
              src="../images/logo-ascs.png"
              alt="australian cyber security centre"
              placeholder="blurred"
              objectFit="contain"
              objectPosition="center"
            /> */}
            </Box>
            <Box className="blockTitle">
              <Heading as="h2" content={sections[1].title} />
              <Text as="p" content={sections[1].text} />
              <Link className="button" to={sections[1].button.link}>
                <span>
                  {sections[1].button.label}
                  <Icon icon={androidArrowForward} size={16} />
                </span>
              </Link>
            </Box>
          </Row>
        </Container>
      </SectionArea>
      <SectionArea>
        <Container>
          <Row className="rowContents">
            <Box className="blockTitle">
              <Heading as="h2" content={sections[2].title} />
              <Text as="p" content={sections[2].text} />
              <Link className="button" to={sections[2].button.link}>
                <span>
                  {sections[2].button.label}
                  <Icon icon={androidArrowForward} size={16} />
                </span>
              </Link>
            </Box>
            <Box className="postsWrap">
              <img src={sections[2].image} alt="x-rd" />
              {/* <StaticImage
              src="../images/logo-ascs.png"
              alt="australian cyber security centre"
              placeholder="blurred"
              objectFit="contain"
              objectPosition="center"
            /> */}
            </Box>
          </Row>
        </Container>
      </SectionArea>
      <SectionArea>
        <Container>
          <Row className="rowContents">
            <Box className="postsWrap">
              <img src={sections[3].image} alt="x-rd" />
              {/* <StaticImage
              src="../images/logo-ascs.png"
              alt="australian cyber security centre"
              placeholder="blurred"
              objectFit="contain"
              objectPosition="center"
            /> */}
            </Box>
            <Box className="blockTitle">
              <Heading as="h2" content={sections[3].title} />
              <Text as="p" content={sections[3].text} />
              <Link className="button" to={sections[3].button.link}>
                <span>
                  {sections[3].button.label}
                  <Icon icon={androidArrowForward} size={16} />
                </span>
              </Link>
            </Box>
          </Row>
        </Container>
      </SectionArea>
      <SectionArea>
        <Container>
          <Row>
            <Box className="blockTitle">
              <Heading as="h2" content="Get in touch with us" />
              <Text
                as="p"
                content="Body placeholder for text paragraph, a paragraph is a self-contained unit of text description."
              />
            </Box>
            <Box className="blockTitle">
              <Link className="button" to="/">
                <span>
                  Submit
                  <Icon icon={androidArrowForward} size={16} />
                </span>
              </Link>
            </Box>
          </Row>
        </Container>
      </SectionArea>
    </ColumnContents>
  );
};

export default Section;
